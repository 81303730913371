import React from "react";

const Programa = () => {
    return (

        <div id="programa">
            <div class="bg-slate-600 pb-1"></div>
           <div class='sm:hidden pt-16 bg-white'>
                <div class="">
                    <div class=" pb-16">
                        <h2 class="text-3xl px-3 text-center font-medium tracking-tight rounded-full py-1 box-decoration-box bg-sky-950 text-white sm:text-3xl">Programa</h2>
                        <div class="px-12 pt-10">
                        </div>
                        <div class=" bg-gradient-to-t from-slate-400 to-white">
                        <div class="flex flex-col pt-10">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div>
                                            <h3 class="text-center pb-8">Horario de las actividades</h3>
                                        </div>
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Jueves 21 Noviembre </th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Viernes 22 Noviembre</th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Sabado 23 Noviembre</th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200">
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">8:30 - 9:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Inauguración</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td class="px-3 py-3 text-center text-sm"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">9:00 - 10:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 1</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 2</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 3</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">10:30 - 12:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias Simultáneas 1</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias Simultáneas 2</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias Simultáneas 3</td>
                                                </tr>
                                                <tr class="bg-lime-100">
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">12:00 - 12:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">12:30 - 14:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 4</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 5</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Conferencia Magistral 6</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">14:00 - 14:30</td>
                                                <td rowspan="2" class="px-3 py-3 text-center text-sm text-gray-800">Talleres 1</td>
                                                <td rowspan="2" class="px-3 py-3 text-center text-sm text-gray-800">Talleres 2</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">14:30 - 16:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Asamblea General y Salida a Museo</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">16:30 - 17:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td rowspan="3" class="px-3 py-3 text-center text-sm text-gray-800">Paseo Santa Lucia</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">17:00 - 18:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Presentación de Posters</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>

                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">18:00 - 19:00</td>
                                                <td rowspan="2"  class="px-3 py-3 text-center text-sm text-gray-800">Coctel de Bienvenida</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">19:00 - 19:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div class='pt-14 pb-20 hidden sm:flex bg-gradient-to-t from-slate-400 to-white '>
                <div class="pt-14 w-1/2 mx-auto">
                    <div class=" text-center justify-center items-center">
                        <h2 class="text-4xl font-medium tracking-tight box-decoration-box rounded-full py-2 bg-sky-950 text-white sm:text-5xl">Programa</h2>
                    </div>
                    <div class="pb-20">
                        <div>
                            <h3 class="text-center pt-14 pb-8">Horario de las actividades</h3>
                        </div>
                        <div>
                            <div class="flex flex-col">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Jueves 21 Noviembre </th>
                                                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Viernes 22 Noviembre</th>
                                                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Sabado 23 Noviembre</th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200">
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">8:30 - 9:00</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Inauguración</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                <td class="px-6 py-4 text-center text-sm"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">9:00 - 10:30</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 1</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 2</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 3</td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">10:30 - 12:00</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Ponencias Simultáneas 1</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Ponencias Simultáneas 2</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Ponencias Simultáneas 3</td>
                                                </tr>
                                                <tr class="bg-lime-100">
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">12:00 - 12:30</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">12:30 - 14:00</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 4</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 5</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Conferencia Magistral 6</td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">14:00 - 14:30</td>
                                                <td rowspan="2" class="px-6 py-4 text-center text-sm text-gray-800">Talleres 1</td>
                                                <td rowspan="2" class="px-6 py-4 text-center text-sm text-gray-800">Talleres 2</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">14:30 - 16:30</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Asamblea General y Salida a Museo</td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">16:30 - 17:00</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                <td rowspan="3" class="px-6 py-4 text-center text-sm text-gray-800">Paseo Santa Lucia</td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">17:00 - 18:00</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800">Presentación de Posters</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>

                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">18:00 - 19:00</td>
                                                <td rowspan="2"  class="px-6 py-4 text-center text-sm text-gray-800">Coctel de Bienvenida</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-6 py-4 text-center text-sm font-medium text-gray-800">19:00 - 19:30</td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                <td class="px-6 py-4 text-center text-sm text-gray-800"></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default Programa;