import React from "react";
import image from './images/monterrey-555080_1920.jpg';
import map from './images/mapa3d.jpg';

const Ubicacion = () => {
    return (

        <div id="ubicacion">
            <div class="bg-slate-600 pb-1"></div>
            <div class='sm:hidden pt-4 bg-white'>
                <div class="">
                    <div class="pt-2">
                        <h2 class="text-3xl px-3 text-center font-bold tracking-tight rounded-full py-1 box-decoration-box bg-gradient-to-r from-sky-500 to-slate-800 text-white sm:text-3xl">Ubicación</h2>
                        <div class="px-12 pt-10">
                        </div>
                        <div class="px-12 pb-12 bg-gradient-to-t from-slate-400 to-white">
                            <p className="text-base px-6 pb-12 pt-6 text-black">Nos complace anunciar que la próxima reunión anual de la Asociación de Profesores de Física de México (APPT-MX) se llevará a cabo en la hermosa ciudad de Monterrey, Nuevo León. Este emocionante evento tendrá lugar en la prestigiosa Universidad Autónoma de Nuevo León (UANL), en su Ciudad Universitaria.</p>
                            <img src={image} class="object-contain rounded-lg shadow "></img>
                            <p class="text-xs text-right">Foto tomada por Mike Foster, Noviembre 2014</p>
                            <p className="text-base px-6 pb-12 pt-12 text-black" >Monterrey, con su vibrante energía y su rica historia, nos brinda el escenario perfecto para este encuentro. 
                            La Ciudad Universitaria de la UANL, con su belleza arquitectónica y su espíritu de excelencia académica, 
                            nos acoge con los brazos abiertos y nos inspira a alcanzar nuevas alturas en nuestra labor como educadores y difusores del conocimiento.</p>
                            <div class="google-maps">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1746.023072595558!2d-100.31442220088749!3d25.72525077225921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866294526f15d0a7%3A0x8c982757dda91d20!2sFCFM%20-%20Facultad%20de%20Ciencias%20F%C3%ADsico%20Matem%C3%A1ticas!5e0!3m2!1ses-419!2smx!4v1717393051826!5m2!1ses-419!2smx"/>
                            </div>
                            <img src={map} alt="" />
                        </div>
                            
                    </div>
                </div>
            </div>
            <div class='hidden sm:flex pt-14 pb-1 bg-gradient-to-t from-sky-950 to-white'>
                <div class="px-40 pt-14">
                    <div class="px-20 text-center">
                        <h2 class="text-4xl font-medium tracking-tight box-decoration-box rounded-full py-2 bg-sky-950 text-white sm:text-5xl">Ubicación</h2>
                    </div>
                    <div class="px-12 pb-1">
                        <div class="grid grid-cols-2 pt-14 pb-12">
                            <div class= "col-start-1">
                                <img src={image} class="object-contain rounded-lg shadow "></img>
                                <p class="text-xs text-right">Foto tomada por Mike Foster, Noviembre 2014</p>
                            </div>
                            <div class="text-justify">
                                <p className="text-lg px-6 pb-2 pt-6 text-black">Nos complace anunciar que la próxima reunión anual de la Asociación de Profesores de Física de México (APPT-MX) se llevará a cabo en la hermosa ciudad de Monterrey, Nuevo León. Este emocionante evento tendrá lugar en la prestigiosa Universidad Autónoma de Nuevo León (UANL), en su Ciudad Universitaria.</p>
                                <p className="text-lg px-6 pb-2 pt-6 text-black" >Monterrey, con su vibrante energía y su rica historia, nos brinda el escenario perfecto para este encuentro. 
                                La Ciudad Universitaria de la UANL, con su belleza arquitectónica y su espíritu de excelencia académica, 
                                nos acoge con los brazos abiertos y nos inspira a alcanzar nuevas alturas en nuestra labor como educadores y difusores del conocimiento.</p>
                            </div>
                           
                        </div>
                        <div class="google-maps py-32">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1746.023072595558!2d-100.31442220088749!3d25.72525077225921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866294526f15d0a7%3A0x8c982757dda91d20!2sFCFM%20-%20Facultad%20de%20Ciencias%20F%C3%ADsico%20Matem%C3%A1ticas!5e0!3m2!1ses-419!2smx!4v1717393051826!5m2!1ses-419!2smx"/>
                        </div>
                        <img src={map} alt="" />
                    </div>
                </div>
            </div>
        </div>
        
    )
}
export default Ubicacion;