import React, {useState, useEffect} from 'react';
import registro_tutorial from './Videos/Registro_tutorial.mp4';
import image from './images/apptmx_logo.png';

const Registro = () => {
const [videoShow, setVideo] = useState(false)
const handleVideo = () => {
    setVideo(!videoShow)
}

    return (

        <div id="registro">
            <div class="bg-slate-600 pb-1"></div>
            <div class='sm:hidden pt-4 bg-white'>
                <div class="text-justify">
                    <div class="pt-2">
                        <h2 class="text-3xl px-3 text-center font-medium tracking-tight rounded-full py-1 box-decoration-box bg-sky-950 text-white sm:text-3xl">Registro</h2>
                        <div class="px-12 pt-10">
                        </div>
                        <div class="px-12 bg-gradient-to-t pb-7 from-slate-400 to-white">
                            <div class="flex items-center justify-center pt-12">
                                <img src={image} class="sm:flex rounded-lg shadow object-cover max-w-xs"></img>
                            </div>
                            <p className="text-lg px-3 pt-11 text-black" >La Asociación Americana de Profesores de Física Sección México y la Universidad Autónoma de Nuevo León
                                 convocan a estudiantes, profesionistas, profesores e investigadores a participar en la XVII Reunión Anual AAPT-MX 2024.</p>
                            <p className="text-lg px-3 pb-16 pt-8 text-black" >A lo largo de esta reunión, se les invita a participar 
                                activamente en las sesiones y a establecer conexiones significativas con sus colegas. Juntos, estamos construyendo un futuro donde la física no 
                                solo sea una disciplina académica, sino también una fuente de inspiración y transformación para nuestra sociedad.</p>
                            <p class="bg-black h-0.5"></p>
                            <p className="text-lg px-3 font-semibold pb-16 pt-8 text-black">Los temas de la XVII Reunión Anual de la AAPTMX 2024 son:</p>
                            <p className="text-lg px-3 pb-8 text-black">1.&emsp;Educación STEM y Aprendizaje Activo</p>
                            <p className="text-lg px-3 pb-8 text-black">2.&emsp;Tecnologías de la Información en la Enseñanza</p>
                            <p className="text-lg px-3 pb-8 text-black">3.&emsp;Investigación educativa y experiencias docente</p>
                            <p className="text-lg px-3 pb-8 text-black">4.&emsp;Divulgación de la física y experiencias de laboratorio</p>
                            <p className="text-lg px-3 pb-8 text-black">5.&emsp;Escenarios para la enseñanza de la física</p>
                            <p className="text-lg px-3 pb-8 text-black">6.&emsp;Retos en la formación docente</p>
                            <p className="text-lg px-3 pb-8 text-black">7.&emsp;Inteligencia artificial generativa en la enseñanza de la física</p>
                            <p className="text-lg px-3 pb-8 text-black">8.&emsp;Inclusión e igualdad de género en la educación STEM</p>
                        </div>
                        <h3 class="text-center pt-14 pb-8 font-normal">Registro de participación y asistencia</h3>
                            <p className="text-lg px-12 pt-8 text-black">Nos complace anunciar el registro para aquellos interesados en participar en las diversas sesiones de nuestra reunión anual de profesores de física en Monterrey, en la Ciudad Universitaria de la UANL.</p>
                            <p className="text-lg px-12 pb-5 pt-8 text-black">Como investigador, tendrás la oportunidad de compartir tus conocimientos, experiencias e investigaciones con una audiencia apasionada y comprometida con el avance de la enseñanza y el aprendizaje de la física.</p>
                            <p className="text-lg px-12 pb-5 pt-4 text-black">La convocatoria para registrarse estará abierta hasta 30 de Octubre y se podrán registrar a través del siguiente url:</p>
                            <div class="text-center pt-4 pb-8">
                                <ul>
                                    <li>
                                        <a className="text-lg text-blue-700  font-medium" href="https://docs.google.com/forms/d/e/1FAIpQLSfGLY4MCl5Ir1Qc6tiDJP6buEl5RcnWMA1psbsd9vSaapwA5w/viewform?usp=sf_link">Link para el registro</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="pt-10 bg-slate-200 rounded-lg">
                                <p className="text-lg pt-8 px-12 text-black">Fechas importantes:</p>
                                <p className="text-base pt-8 px-12 text-black">Fecha límite para registro: <b>30 de Octubre del 2024</b></p>
                                <p className="text-base pt-8 px-12 text-black">Notificación de aceptación: <b>1 de Noviembre del 2024</b></p>
                                <p className="text-base px-12 pb-2 text-black">Fecha del evento: <b>21 al 23 de Noviembre del 2024</b></p>
                                <p className="text-base px-12 pb-5 pt-2 text-black">Nota: El número de espacios para los participantes es limitado, por lo que recomendamos realizar el registro lo antes posible para garantizar tu participación</p>
                                <p className="text-base px-12 pb-2 pt-8 text-black">Los requisitos del documento a presentar para darse de alta son los siguientes:</p>
                                <p className="text-base px-20 pt-2 text-black">-Título</p>
                                <p className="text-base px-20 pt-2 text-black">-Instituciones de adscripción</p>
                                <p className="text-base px-20 pt-2 text-black">-Resumen</p>
                                <p className="text-base px-20 pt-2 text-black">-Autores Referenciados</p>
                                <div class="bg-zinc-100 rounded-lg">
                                <p className="text-base px-20 pt-2 text-black">-Temas seleccionados:</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;1.&emsp;Educación STEM y Aprendizaje Activo</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;2.&emsp;Tecnologías de la Información en la Enseñanza</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;3.&emsp;Investigación educativa y experiencias docente</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;4.&emsp;Divulgación de la física y experiencias de laboratorio</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;5.&emsp;Escenarios para la enseñanza de la física</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;6.&emsp;Retos en la formación docente</p>
                                    <p className="text-base px-20 pt-2 text-black text-left">&emsp;7.&emsp;Inteligencia artificial generativa en la enseñanza de la física</p>
                                    <p className="text-base px-20 pt-2 pb-2 text-black text-left">&emsp;8.&emsp;Inclusión e igualdad de género en la educación STEM</p>
                                </div>
                                <p className="text-base px-20 pb-12 pt-2 text-black">-Palabras claves</p>
                            <p className="text-lg px-12 pt-8 text-black font-medium">La propuesta para los resúmenes de las presentaciones orales, además de cumplir con los requisitos antes mencionados también deben de satisfacer los siguientes puntos:</p>
                            <p className="text-base px-12 pt-7  text-black font-normal">· Los documentos deben de tener máximo 350 palabras para el resumen.</p>
                            <p className="text-base px-12 pt-4 text-black font-normal">· El título debe de ser presentado en <b>MAYÚSCULAS</b>, <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>CENTRADO.</b></p>
                            <p className="text-base px-12 pt-4 text-black font-normal">· Los autores, separados los nombres por comas, letra <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>centrado</b> y <b>espaciado sencillo.</b></p>
                            <p className="text-base px-12 pt-4 text-black font-normal">· Los nombres de instituciones de los autores, letra <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>centrado</b>, <b>espaciado sencillo.</b></p>
                            <p className="text-base px-12 pt-4 text-black font-normal">· Si es más de una institución, relacionarlas con un superíndice numérico progresivo con cada autor.</p>
                            <p className="text-base px-12 pt-4 pb-20 text-black font-normal">· El documento deberá ser enviado en formato PDF </p>
                            </div>
                            <h2 class="text-center pt-24 pb-4 font-normal">Costos</h2>
                        <div class="bg-white rounded-lg shadow-lg">
                            <p className="text-lg px-16 pt-8 pb-15 text-black" >Los costos para la inscripción se presentan a continuación asi como la información requerida para hacer válido el registro</p>
                            <div class="pt-10 pb-10 text-lg text-center">
                                <table class="table-fixed border-separate border-spacing-y-2 border-spacing-x-2 rounded-lg bg-slate-300 w-11/12 mx-auto">
                                    <thead class="uppercase">
                                        <tr>
                                        <th>Participante</th>
                                        <th>Presencial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>Miembros </td>
                                        <td>$650MXN</td>
                                        </tr>
                                        <tr>
                                        <td>No Miembros</td>
                                        <td>$650MXN</td>
                                        </tr>
                                        <tr>
                                        <td>Estudiantes</td>
                                        <td>$350MXN</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-justify px-24 rounded-lg bg-zinc-200">
                                <p className="text-m pt-8 pb-8 text-black" >Pasos a realizar para la inscripción:</p>
                                <div class="relative text-center">
                                    <button type="button" class="inline-flex items-center pl-6 text-gray-900 hover:text-sky-700" onClick={handleVideo}>
                                        <span class="text-xl text-blue-700">Video Tutorial</span>
                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                        </svg>
                                    </button>

                                    <div className={videoShow ? 'absolute left-1/2 z-10 mt-5 w-max max-w-max  -translate-x-1/2':'absolute left-1/2 z-10 mt-5 w-max max-w-max -translate-x-1/2 hidden'}>
                                        <div class="w-screen max-w-screen-2xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                            <div class="p-4">
                                                <video controls class="w-full h-full object-cover rounded-lg">
                                                    <source src={registro_tutorial} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm pt-8 text-black" >1.- El participante llena el registro mediante la plataforma Google Forms donde anotará sus datos, adjuntará el documento resumen y especificará si requerirá factura.</p>
                                <p className="text-sm pt-8 text-black" >2.- Una vez enviado el registro, el remitente recibirá la información para realizar el pago proporcionando datos bancarios.</p>
                                <p className="text-sm pt-8 text-black" >3.- Se realiza el pago correspondiente siguiendo los lineamientos para la inscripción y se mandara el comprobante de pago (ticket o captura de pantalla desde el portal del banco) y su nombre al siguiente correo electrónico:</p>
                                <p className="text-sm px-3 pt-8 text-black text-center"><u>omar.gonzalezmz@uanl.edu.mx</u> </p>
                                <p className="text-sm pt-8 text-black" >4.- En caso de requerir factura, los requisitos a adjuntar con el comprobante de pago serán los siguientes:</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Solicitud de Factura </p>
                                <p className="text-sm px-3 pb-3 pt-3 text-black" >&#x2022; Comprobante de pago legible</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Constancia de Situación Fiscal Completa</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Uso del CFDI y Método de pago</p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>Nota: Si desea solicitar Factura es indispensable que el pago sea dentro de los primeros 20 días del mes para que la Factura pueda timbrarse en tiempo y forma, es decir dentro del mes correspondiente al pago)</b></p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>IMPORTANTE: Todo pago efectuado deberá ser informado dentro del mes para ser tomado en cuenta en el registro.</b></p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>* Cualquier pago realizado no aplica rembolso.</b></p>
                                <p className="text-sm px-3 pb-20 pt-3 text-black" ><b>* El pago de la inscripción deberá ser + IVA (16%) en caso de requerir factura.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* ----------------------------------------------DESKTOP---------------------------------------------- */}
            <div class='pt-14 pb-20 hidden sm:flex bg-gradient-to-t from-slate-400 to-white'>
                <div class="pt-14 w-1/2 mx-auto">
                    <div class="px-6 text-center">
                        <h2 class="text-4xl font-medium tracking-tight box-decoration-box rounded-full py-2 bg-sky-950 text-white sm:text-5xl ">Registro</h2>
                    </div>
                    <div class="text-justify pb-20">
                        <div class="flex items-center justify-center pt-12">
                            <img src={image} class="sm:flex rounded-lg shadow object-cover max-w-xs"></img>
                        </div>
                        <p className="text-lg px-5 pb-3 pt-14 text-black " >La Asociación Americana de Profesores de Física Sección México y la Universidad Autónoma de Nuevo León 
                            convocan a estudiantes, profesionistas, profesores e investigadores a participar en la XVII Reunión Anual AAPT-MX 2024.</p>
                        <p className="text-lg px-5 pb-20 pt-8 text-black" >A lo largo de esta reunión, se les invita a participar 
                                activamente en las sesiones y a establecer conexiones significativas con sus colegas. Juntos, estamos construyendo un futuro donde la física no 
                                solo sea una disciplina académica, sino también una fuente de inspiración y transformación para nuestra sociedad.</p>
                            <p class="bg-black h-0.5"></p>
                            <p className="text-lg px-3 font-semibold text-center pb-8 pt-8 text-black">Los temas de la XVII Reunión Anual de la AAPTMX 2024 son:</p>
                            <p className="text-lg px-3 pb-8 text-black">1.&emsp;Educación STEM y Aprendizaje Activo</p>
                            <p className="text-lg px-3 pb-8 text-black">2.&emsp;Tecnologías de la Información en la Enseñanza</p>
                            <p className="text-lg px-3 pb-8 text-black">3.&emsp;Investigación educativa y experiencias docente</p>
                            <p className="text-lg px-3 pb-8 text-black">4.&emsp;Divulgación de la física y experiencias de laboratorio</p>
                            <p className="text-lg px-3 pb-8 text-black">5.&emsp;Escenarios para la enseñanza de la física</p>
                            <p className="text-lg px-3 pb-8 text-black">6.&emsp;Retos en la formación docente</p>
                            <p className="text-lg px-3 pb-8 text-black">7.&emsp;Inteligencia artificial generativa en la enseñanza de la física</p>
                            <p className="text-lg px-3 pb-8 text-black">8.&emsp;Inclusión e igualdad de género en la educación STEM</p>
                            <p class="bg-black h-0.5"></p>
                        <h2 class="text-center pt-14 pb-8 font-normal">Registro de participación y asistencia</h2>
                        <div class="bg-white rounded-lg shadow-lg">
                            <p className="text-lg px-20 pt-16 text-black">Nos complace anunciar el registro para aquellos interesados en participar como expositores en las diversas sesiones de nuestra reunión anual de profesores de física en Monterrey, en la Ciudad Universitaria de la UANL.</p>
                            <p className="text-lg px-20 pb-5 pt-8 text-black">Como investigador, tendrás la oportunidad de compartir tus conocimientos, experiencias e investigaciones con una audiencia apasionada y comprometida con el avance de la enseñanza y el aprendizaje de la física.</p>
                            <p className="text-lg px-20 pb-5 pt-4 text-black">La convocatoria para registrarse estará abierta hasta el 30 de Octubre y se podrán registrar a través del siguiente url:</p>
                            <div class="text-center pt-4 pb-8">
                                <ul>
                                    <li>
                                        <a className="text-lg text-blue-700 hover:text-red-700   font-medium" href="https://docs.google.com/forms/d/e/1FAIpQLSfGLY4MCl5Ir1Qc6tiDJP6buEl5RcnWMA1psbsd9vSaapwA5w/viewform?usp=sf_link">Link para registro</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="px-24 pt-10 bg-slate-200 rounded-lg">
                                <p className="text-lg px-12 pt-6 text-black">Fechas importantes:</p>
                                <p className="text-base px-12 text-black">Fecha límite para registro: <b>30 de Octubre del 2024</b></p>
                                <p className="text-base px-12 text-black">Notificación de aceptación: <b>1 de Noviembre del 2024</b></p>
                                <p className="text-base px-12 pb-2 text-black">Fecha del evento: <b>21 al 23 de Noviembre del 2024</b></p>
                                <p className="text-base px-12 pb-5 pt-2 text-black"><b>Nota:</b> El número de espacios para los participantes es limitado, por lo que recomendamos realizar el registro lo antes posible para garantizar su participación.</p>
                                <p className="text-base px-12 pb-2 pt-8 text-black">Los requisitos del documento a presentar para darse de alta son los siguientes:</p>
                                <p className="text-base px-20 pt-2 text-black">-Título</p>
                                <p className="text-base px-20 pt-2 text-black">-Instituciones de adscripción</p>
                                <p className="text-base px-20 pt-2 text-black">-Resumen</p>
                                <p className="text-base px-20 pt-2 text-black">-Autores Referenciados</p>
                                <div class="bg-zinc-100 rounded-lg">
                                <p className="text-base px-20 pt-2 text-black">-Temas seleccionados:</p>
                                    <p className="text-base px-28 pt-2 text-black">1.&emsp;Educación STEM y Aprendizaje Activo</p>
                                    <p className="text-base px-28 pt-2 text-black">2.&emsp;Tecnologías de la Información en la Enseñanza</p>
                                    <p className="text-base px-28 pt-2 text-black">3.&emsp;Investigación educativa y experiencias docente</p>
                                    <p className="text-base px-28 pt-2 text-black">4.&emsp;Divulgación de la física y experiencias de laboratorio</p>
                                    <p className="text-base px-28 pt-2 text-black">5.&emsp;Escenarios para la enseñanza de la física</p>
                                    <p className="text-base px-28 pt-2 text-black">6.&emsp;Retos en la formación docente</p>
                                    <p className="text-base px-28 pt-2 text-black">7.&emsp;Inteligencia artificial generativa en la enseñanza de la física</p>
                                    <p className="text-base px-28 pt-2 pb-2 text-black">8.&emsp;Inclusión e igualdad de género en la educación STEM</p>
                                </div>
                                
                                <p className="text-base px-20 pb-20 pt-2 text-black">-Palabras claves</p>
                            </div>
                            <p className="text-lg px-20 pt-16 text-black font-medium">La propuesta para los resúmenes de las presentaciones orales, además de cumplir con los requisitos antes mencionados también deben de satisfacer los siguientes puntos:</p>
                            <p className="text-base px-20 pt-7  text-black font-normal">· Los documentos deben de tener máximo 350 palabras para el resumen.</p>
                            <p className="text-base px-20 pt-4 text-black font-normal">· El título debe de ser presentado en <b>MAYÚSCULAS</b>, <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>CENTRADO.</b></p>
                            <p className="text-base px-20 pt-4 text-black font-normal">· Los autores, separados los nombres por comas, letra <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>centrado</b> y <b>espaciado sencillo.</b></p>
                            <p className="text-base px-20 pt-4 text-black font-normal">· Los nombres de instituciones de los autores, letra <b>ARIAL 12</b>, <b>NEGRITAS</b>, <b>centrado</b>, <b>espaciado sencillo.</b></p>
                            <p className="text-base px-20 pt-4 text-black font-normal">· Si es más de una institución, relacionarlas con un superíndice numérico progresivo con cada autor.</p>
                            <p className="text-base px-20 pt-4 pb-20 text-black font-normal">· El documento deberá ser enviado en formato PDF </p>
                        </div>
                        <h3 class="text-center pt-24 pb-8 font-normal">Costos</h3>
                        <div class="bg-white rounded-lg shadow-lg text-justify">
                            <p className="text-lg px-20 pt-16 pb-15 text-black" >Los costos para la inscripción se presentan a continuación asi como la información requerida para hacer válido el registro</p>
                            <div class="pt-10 pb-20 text-lg text-center">
                                <table class="table-fixed border-separate border-spacing-y-3 border-spacing-x-8 rounded-lg bg-slate-300 w-1/2 mx-auto">
                                    <thead class="uppercase">
                                        <tr>
                                        <th>Participante</th>
                                        <th>Costo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>Miembros </td>
                                        <td>$650MXN</td>
                                        </tr>
                                        <tr>
                                        <td>No Miembros</td>
                                        <td>$650MXN</td>
                                        </tr>
                                        <tr>
                                        <td>Estudiantes</td>
                                        <td>$350MXN</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-justify px-24 rounded-lg bg-zinc-200">
                                <p className="text-m pt-8 pb-8 text-black" >Pasos a realizar para la inscripción:</p>
                                <div class="relative text-center">
                                    <button type="button" class="inline-flex items-center pl-6 text-blue-700 hover:text-red-700" onClick={handleVideo}>
                                        <span class="text-xl text-blue-700 hover:text-red-700">Video Tutorial</span>
                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                        </svg>
                                    </button>

                                    <div className={videoShow ? 'absolute left-1/2 z-10 mt-5 w-max max-w-max  -translate-x-1/2':'absolute left-1/2 z-10 mt-5 w-max max-w-max -translate-x-1/2 hidden'}>
                                        <div class="w-screen max-w-screen-2xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                            <div class="p-4">
                                                <video controls class="w-full h-full object-cover rounded-lg">
                                                    <source src={registro_tutorial} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm pt-8 text-black" >1.- El participante llena el registro mediante la plataforma Google Forms donde anotará sus datos, adjuntará el documento resumen y especificará si se requerirá factura.</p>
                                <p className="text-sm pt-8 text-black" >2.- Una vez enviado el registro, el remitente recibirá la información para realizar el pago proporcionando datos bancarios.</p>
                                <p className="text-sm pt-8 text-black" >3.- Se realiza el pago correspondiente siguiendo los lineamientos para la inscripción y se mandara el comprobante de pago (ticket o captura de pantalla desde el portal del banco) y su nombre al siguiente correo electrónico:</p>
                                <p className="text-sm px-3 pt-8 text-black text-center"><u>omar.gonzalezmz@uanl.edu.mx</u> </p>
                                <p className="text-sm pt-8 text-black" >4.- En caso de requerir factura, los requisitos a adjuntar con el comprobante de pago serán los siguientes:</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Solicitud de Factura </p>
                                <p className="text-sm px-3 pb-3 pt-3 text-black" >&#x2022; Comprobante de pago legible</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Constancia de Situación Fiscal Completa</p>
                                <p className="text-sm px-3 pt-3 text-black" >&#x2022; Uso del CFDI y Método de pago</p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>Nota: Si desea solicitar Factura es indispensable que el pago sea dentro de los primeros 20 días del mes para que la Factura pueda timbrarse en tiempo y forma, es decir dentro del mes correspondiente al pago.</b></p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>IMPORTANTE: Todo pago efectuado deberá ser informado dentro del mes para ser tomado en cuenta en el registro.</b></p>
                                <p className="text-sm px-3 pt-3 text-black" ><b>* Cualquier pago realizado no aplica rembolso.</b></p>
                                <p className="text-sm px-3 pb-20 pt-3 text-black" ><b>* El pago de la inscripción deberá ser + IVA (16%) en caso de requerir factura.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default Registro;