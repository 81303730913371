import React from "react";
import image from './images/defaultavatar.jpg';
import OmarGonzalez from './images/Omar_Gonzalez.jpg';
import EliMartin from './images/Eli_Martin.jpg';
import JuanCarlosRuiz from './images/Juan-Carlos-Ruiz.jpg';
import JavierAlmaguer from './images/JAVIER-ALMAGUER.jpg';
import FranciscoHernandez from './images/Francisco-Hernández-Cabrera.jpg';
import AlejandroLara from './images/Alejandro_Lara.jpg';
import ElizabethGuajardo from './images/Elizabeth_Guajardo.jpg';
import ErickAzaelRamirez from './images/ErickAzaelRamirez.jpg';
import JoseApolinarLoyola from './images/JoseApolinarLoyola.jpg';
import MiguelAlejandro from './images/miguel-alejandro-candelaria.jpeg';
import AltilanoHuerta from './images/Dr-Atilano.jpg';
import MariadelCarmen from './images/MariaDelCarmen.jpg';
import EduardoGerardo from './images/EduardoGerardo.jpg';
import AleidaMagdalena from './images/DraAleida.jpg';
import AlvaroEduardo from './images/DrAlvaroEduardo.jpg';

const Comite = () => {
    return (

        <div id="comite" className='w-full bg-white' >
           <div class="bg-white  sm:py-32">
                <div class="sm:hidden pt-14 pb-16 mx-auto">
                        <h3 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comité</h3>
                            <p class="pt-10 px-10">La AAPT‐MX organiza anualmente esta reunión y sus sedes anteriores han sido:</p>
                            <p class="pt-10 px-10 font-light text-base">• Tecnológico de Monterrey, Campus Monterrey (2008)</p>
                            <p class="pt-2 px-10 font-light text-base">• Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada del IPN, Unidad Legaria (2009)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad de Guanajuato (2010)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Politécnica de San Luís Potosí (2011)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Politécnica del Golfo de México (2012)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de Baja California, Campus Ensenada (2013)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad de Guanajuato (2014)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Nacional Autónoma de México (2015)</p>
                            <p class="pt-2 px-10 font-light text-base">• Centro de Educación Continua del IPN, Unidad Cancún (2016)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de San Luis Potosí (2017)</p>
                            <p class="pt-2 px-10 font-light text-base">•Instituto Tecnológico de Monterrey, Campus Monterrey (2018)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de Ciudad Juárez (2019)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de San Luis Potosí, virtual (2020)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad de Guadalajara, virtual (2021)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de San Luis Potosí, virtual (2022)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de Baja California, Campus Ensenada (2023)</p>
                            <p class="pt-2 px-10 font-light text-base">• Universidad Autónoma de Nuevo León (2024)</p>
                </div>
                <div class='sm:hidden pt-5 bg-white'>
                    <div class="bg-white">
                            <div class="grid max-w-xl px-4 gap-x-8 gap-y-10 xl:grid-cols-3 pb-10">
                                <div class="text-justify">
                                    <h3 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-lg">Mesa directiva</h3>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">El Comité Organizador está comprometido con la excelencia en la planificación y 
                                        ejecución de nuestra reunión anual. Han trabajado arduamente para diseñar un programa emocionante, 
                                        asegurar la participación de destacados ponentes y garantizar que todos los detalles logísticos estén 
                                        cuidadosamente coordinados.</p>
                                </div>
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Jesús Manuel Sáenz Villela</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Presidente Interino</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Santa Esmeralda Teresa</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Ex-Presidenta</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Vicente Torres Zúñiga</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Presidente Electo 2025</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Juan Tapia Mercado</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vicepresidente</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Carmen del Pilar Suárez, Rodríguez</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Representante AAPT Estados Unidos</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Marco Noguez</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de Licenciatura</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. César García</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de Posgrado</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Amalia Guerrero Almanza</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de nivel Medio Superior</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="max-w-2xl text-center">
                                        <h3 class=" text-center py-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl bg-slate-300">Comité Local</h3>
                                </div>
                            <div class="grid xl:grid-cols-3 px-4 bg-slate-300 pb-10">
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AltilanoHuerta} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Atilano Martínez Huerta</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Director</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JoseApolinarLoyola} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. José Apolinar Loyola Rodríguez</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Administrativo</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={MiguelAlejandro} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Miguel Alejandro Candelaria Coronado</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Académico</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AlvaroEduardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Álvaro Eduardo Cordero Franco</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Posgrado</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AleidaMagdalena} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Aleida Magadlena Gil González</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirectora de Calidad e Innovación Educativa</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={MariadelCarmen} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. María del Carmen Martínez Cejudo</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirctora de Educación Continua y Divulgación de la Ciencia</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={EliMartin} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Elí Martín Lafuente Guerra</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector de Relaciones Públicas</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={EduardoGerardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Eduardo Gerardo Pérez Tijerina</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Innovación Tecnológica y Vinculación de la Ciencia</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={ErickAzaelRamirez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Erick Azael Ramírez Aguilar</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector de Asuntos Universitarios</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={OmarGonzalez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Omar González Amezcua</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Coordinador de Licenciatura en Física</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                            </div>
                            <div class="max-w-2xl text-center">
                                        <h3 class=" text-center py-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl bg-white">Profesores</h3>
                                </div>
                            <div class="grid xl:grid-cols-3 px-4 bg-white pb-10">
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JuanCarlosRuiz} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Juan Carlos Ruiz Mendoza</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={FranciscoHernandez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Francisco Cabrera Hernández</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JavierAlmaguer} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Francisco Javier Almaguer</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AlejandroLara} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.C. Alejandro Cagliostro Lara Neave</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={ElizabethGuajardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Elizabeth Guajardo García</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesora UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                </div>
                <div class="hidden sm:flex pt-5 w-1/2 mx-auto">
                    <div class="pt-2">
                    <h3 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comité</h3>
                        <div class="text-justify">
                            <p class="pt-10">La AAPT‐MX organiza anualmente esta reunión y sus sedes anteriores han sido:</p>
                            <p class="pt-10 px-5 font-light">• Tecnológico de Monterrey, Campus Monterrey (2008)</p>
                            <p class="pt-2 px-5 font-light">• Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada del IPN, Unidad Legaria (2009)</p>
                            <p class="pt-2 px-5 font-light">• Universidad de Guanajuato (2010)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Politécnica de San Luís Potosí (2011)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Politécnica del Golfo de México (2012)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de Baja California, Campus Ensenada (2013)</p>
                            <p class="pt-2 px-5 font-light">• Universidad de Guanajuato (2014)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Nacional Autónoma de México (2015)</p>
                            <p class="pt-2 px-5 font-light">• Centro de Educación Continua del IPN, Unidad Cancún (2016)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de San Luis Potosí (2017)</p>
                            <p class="pt-2 px-5 font-light">•Instituto Tecnológico de Monterrey, Campus Monterrey (2018)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de Ciudad Juárez (2019)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de San Luis Potosí, virtual (2020)</p>
                            <p class="pt-2 px-5 font-light">• Universidad de Guadalajara, virtual (2021)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de San Luis Potosí, virtual (2022)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de Baja California, Campus Ensenada (2023)</p>
                            <p class="pt-2 px-5 font-light">• Universidad Autónoma de Nuevo León (2024)</p>
                        </div>       
                    </div>      
                </div>
            </div>
                <div class='hidden sm:flex pt-5 pb-20 bg-white'>
                    <div class="bg-white py-24 px-40">
                            <div class="mx-auto grid gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3 pb-44">
                                <div class="max-w-2xl text-justify pt-4">
                                    <h3 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mesa directiva</h3>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">El Comité Organizador está comprometido con la excelencia en la planificación y 
                                        ejecución de nuestra reunión anual. Han trabajado arduamente para diseñar un programa emocionante, 
                                        asegurar la participación de destacados ponentes y garantizar que todos los detalles logísticos estén 
                                        cuidadosamente coordinados.</p>
                                </div>
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 py-8 px-8 xl:col-span-2 rounded-lg shadow-xl">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Jesús Manuel Sáenz Villela</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Presidente Interino</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Santa Esmeralda Teresa</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Ex-Presidenta</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Juan Tapia Mercado</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vicepresidente</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Vicente Torres Zúñiga</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Presidente Electo 2025</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Carmen del Pilar Suárez Rodríguez</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Representante AAPT Estados Unidos</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Marco Noguez</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de Licenciatura</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. César García</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de Posgrado</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <p className="px-0.5 py-5 rounded-lg bg-gray-700" ></p>
                                            <div>
                                                <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Amalia Guerrero Almanza</h4>
                                                <p class="text-sm font-semibold leading-6 text-indigo-600">Vocal de nivel Medio Superior</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="pt-15 mx-auto grid px-10 py-10 lg:px-10 xl:grid-cols-3 bg-slate-300 bg-auto rounded-lg shadow-xl">
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AltilanoHuerta} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Atilano Martínez Huerta </h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Director</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JoseApolinarLoyola} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. José Apolinar Loyola Rodríguez</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Administrativo</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={MiguelAlejandro} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Miguel Alejandro Candelaria Coronado</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirección Administrativa</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AlvaroEduardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Álvaro Eduardo Cordero Franco</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Posgrado</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AleidaMagdalena} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Aleida Magadlena Gil González</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirectora de Calidad e Innovación Educativa</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={MariadelCarmen} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. María del Carmen Martínez Cejudo</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirectora de Educación Continua y Divulgación de la Ciencia</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={EliMartin} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Elí Martín La Fuente Guerra</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Relaciones Públicas</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={EduardoGerardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Eduardo Gerardo Pérez Tijerina</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector Innovación Tecnológica y Vinculación de la Ciencia</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={ErickAzaelRamirez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.A. Erick Azael Ramírez Aguilar</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Subdirector de Asuntos Universitarios</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={OmarGonzalez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Omar González Amezcua </h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Coordinador de Licenciatura en Física </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                <div class="max-w-2xl text-center">
                                    <h3 class="py-96 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comité Local</h3>
                                </div>
                            </div>
                            <div class="pt-24">
                            <div class=" mx-auto grid px-10 py-10 lg:px-10 xl:grid-cols-3 bg-slate-300 bg-auto rounded-lg shadow-xl">
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JuanCarlosRuiz} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Juan Carlos Ruiz Mendoza</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={FranciscoHernandez} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Francisco Cabrera Hernández</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={JavierAlmaguer} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Francisco Javier Almaguer</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={AlejandroLara} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">M.C. Alejandro Cagliostro Lara Neave</h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesor UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="flex items-center gap-x-6">
                                                <img class="h-20 w-20 rounded-full" src={ElizabethGuajardo} alt=""/>
                                                <div>
                                                    <h4 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dra. Elizabeth Guajardo García </h4>
                                                    <p class="text-sm font-semibold leading-6 text-indigo-600">Profesora UANL FCFM</p>
                                                </div>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                    <div class="max-w-2xl text-center">
                                        <h3 class="py-64 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Profesores</h3>
                                    </div>
                            </div>
                            </div>
                            
                        </div>
                </div>
        </div>


    )
}
export default Comite;