import React from "react";
import image from './images/defaultavatar.jpg';
import imageCesarEduardo from'./images/Cesar_MoraFoto.png';
import NiviaTomasa from './images/NIVIA-ALVAREZ-AGUILAR.png';
import DavidSokoloff from './images/DavidSokoloff.png';
import Lizbeth from './images/Lizbeth.png';
import Francisco from './images/Francisco-Hernández-Cabrera.jpg';
import JuanCarlosRuiz from './images/Juan-Carlos-Ruiz.jpg';

const Ponentes = () => {
    return (

        <div id="ponentes">
            <div class="bg-slate-600 pb-0.5"></div>
            <div class='sm:hidden bg-neutral-900'>
                <div class="bg-neutral-900 py-24 sm:py-32 ">
                    <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3 bg-neutral-900">
                        <div class="max-w-2xl">
                            <h2 class="text-center text-3xl pt-16 font-medium tracking-tight text-white sm:text-5xl">Ponentes</h2>
                            <p class="mt-6 text-lg text-justify leading-8 text-white">Este evento promete ser una plataforma única para el intercambio de conocimientos, la presentación de investigaciones innovadoras y la discusión de los avances más recientes en diversas ramas de la física.</p>
                            <p class="mt-6 text-lg text-justify leading-8 text-white">A continuación, les presentamos un breve resumen de algunos de los ponentes que nos acompañarán en esta ocasión</p>               
                        </div>
                        <ul role="list" class="grid gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2 pb-10">
                            <li>
                                <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                    <img class="h-20 w-20 rounded-lg" src={imageCesarEduardo} alt=""/>
                                    <div>
                                        <h3 class="text-base font-semibold leading-7 tracking-tight text-white">Dr Cesar Mora Leya</h3>
                                        <p class="text-sm font-semibold leading-6 text-black"></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                    <img class="h-20 w-20 rounded-lg" src={DavidSokoloff} alt=""/>
                                    <div>
                                        <h3 class="text-base font-semibold leading-7 tracking-tight text-white">David R. Sokoloff</h3>
                                        <p class="text-sm font-semibold leading-6 text-black"></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                    <img class="h-20 w-20 rounded-lg" src={NiviaTomasa} alt=""/>
                                    <div>
                                        <h3 class="text-base font-semibold leading-7 tracking-tight text-white">Dra Nivia Alvarez Aguilar</h3>
                                        <p class="text-sm font-semibold leading-6 text-black"></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                    <img class="h-20 w-16 rounded-lg" src={Lizbeth} alt=""/>
                                    <div>
                                        <h3 class="text-base font-semibold leading-7 tracking-tight text-white">Dra Lizbeth Habib Mireles</h3>
                                        <p class="text-sm font-semibold leading-6 text-black"></p>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class='bg-neutral-900 hidden sm:flex pt-14 pb-20'>
                    <div class="bg-neutral-900 pt-24 px-40 text-justify">
                            <h2 class="text-5xl font-medium tracking-tight text-white text-center px-52">Ponentes</h2>
                            <p class="mt-6 text-lg leading-8 text-white pt-5 px-52">Este evento promete ser una plataforma única para el intercambio de conocimientos, la presentación de investigaciones innovadoras y la discusión de los avances más recientes en diversas ramas de la física.</p>
                            <p class="mt-6 text-lg leading-8 text-white pb-24 px-52">A continuación, les presentamos un breve resumen de algunos de los ponentes que nos acompañarán en esta ocasión</p>
                            <div class="mx-auto grid gap-x-8 gap-y-20 px-6 lg:px-12 xl:grid-cols-3">
                                <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-1 col-start-2 sm:gap-y-16 xl:col-span-3">
                                    <li>
                                        <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                            <img class="h-80 w-80 rounded-lg" src={imageCesarEduardo} alt=""/>
                                            <div class=' px-6 py-6'>
                                                <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dr. César Eduardo Mora Ley </h3>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">Se formó en la Universidad de Guadalajara como Licenciado en Física, 
                                                    Licenciado en Enseñanza de las Matemáticas y Especialista en Física Educativa. Maestro en Ciencias en Física en el CINVESTAV-IPN 
                                                    y en Filosofía Aplicada en la UVAQ. Es Doctor en Ciencias en Física por la Universidad Autónoma Metropolitana y Doctor en Educación 
                                                    por la Universidad de Burgos. Es Profesor del Programa de Posgrado en Física Educativa del Centro de Investigación en Ciencia Aplicada 
                                                    y Tecnología Avanzada Unidad Legaria del Instituto Politécnico Nacional, del cual es fundador. Es miembro del Sistema Nacional de 
                                                    Investigadores, Nivel I. Sus principales áreas de trabajo son la Enseñanza y Filosofía de la Física, así como la Física Teórica.
                                                    <br></br><br></br>
                                                    Sus más recientes actividades en proyectos de investigación en Enseñanza de la Física son las siguientes:<br></br>  
                                                    -Editor asociado de la Revista Mexicana de Física E (2023-2025).<br></br>
                                                    -Presidente pasado de la División de Enseñanza de la Sociedad Mexicana de Física A. C. <br></br>
                                                    -Director de las revistas Latin American Journal of Physics Education (2007) y Latin American Journal of Science Education (2014).<br></br>
                                                    -Coeditor de la revista EASI: Ingeniería y Ciencias Aplicadas en la Industria (2022-2026).<br></br>
                                                    -Miembro asociado de la International Commission of Physics Education perteneciente a la International Union of Pure and Applied Physics (ICPE-IUPAP) (2022-2024).<br></br>
                                                    -Presidente electo del International Council of Associations for Science Education (ICASE) y representante de América Latina (2023-2026).<br></br>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                            <img class="h-80 w-80 rounded-lg" src={DavidSokoloff} alt=""/>
                                            <div>
                                                <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">David R. Sokoloff</h3>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">David R. Sokoloff es profesor emérito de Física en la Universidad de Oregón. 
                                                    Comenzó sus estudios de Física en el Queens College de la City University de Nueva York y obtuvo su doctorado en Física de AMO en el 
                                                    Massachusetts Institute of Technology en 1972 con Ali Javan. Antes de su puesto actual, fue miembro del cuerpo docente de la Western Illinois University 
                                                    y de la University of Michigan, Dearborn. Ha sido profesor visitante en la California Polytechnic State University, San Luis Obispo, la Tufts University,
                                                     la Swinburne University of Technology, Melbourne, Australia, y la Universidad Nacional de San Luis, San Luis, Argentina, y pasó un año como Director Científico de WISTEC, 
                                                     el centro científico práctico en Eugene, Oregón.
                                                </p><br></br>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">
                                                Desde 1999, ha formado parte de un equipo de la UNESCO que presenta talleres de aprendizaje activo en países en desarrollo. 
                                                Desde 2004, se han presentado 37 talleres de Aprendizaje Activo en Óptica y Fotónica (ALOP, por sus siglas en inglés) 
                                                con la participación de más de 1100 docentes de más de 30 países. Entre los países donde se han realizado ALOP se encuentran Ghana, 
                                                Túnez, Marruecos, India, Tanzania, Brasil, México, Zambia, Camerún, Colombia, Nepal, Chile, Argelia, Filipinas, Ruanda, Armenia, Tailandia
                                                , Etiopía, Georgia, Indonesia, Mauricio, Sudáfrica, Pakistán, Bolivia, Panamá, Perú y Ecuador. Es el editor de Aprendizaje Activo en Óptica 
                                                y Fotónica, el manual de capacitación publicado por la UNESCO para su uso en estos talleres. Además de actividades seleccionadas de RTP e ILD,
                                                sus contribuciones a este manual incluyen una serie de trucos de magia óptica que ha utilizado para enseñar conceptos de óptica a nivel 
                                                universitario, al público, a la clase de cuarto grado de su hijo y a estudiantes de primero y cuarto grado en Australia. También ha presentado
                                                talleres de aprendizaje activo sobre óptica y otros temas de física en Chile, Colombia, Perú, Ecuador, México, Australia, Nueva Zelanda,
                                                Malasia, Filipinas, Argentina, Canadá, Francia, Italia, Japón, Eslovenia, República Checa, Vietnam, Corea, China, Sri Lanka y en todo Estados Unidos.
                                                </p><br></br>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300 ">En 2020, David R. Sokoloff recibió la Medalla Hans Christian Oersted de la 
                                                    Asociación Estadounidense de Profesores de Física (AAPT) por su "impacto sobresaliente, generalizado y duradero en la enseñanza de la física"
                                                     (Conferencia Oersted) y la Medalla GIREP (Medalla del Groupe International de Recherche sur l'Enseignement de la Physique). También recibió 
                                                     el premio Robert A. Millikan de la AAPT en 2007. Fue elegido presidente de la Asociación Estadounidense de Profesores de Física en 2008 y 
                                                     completó el ciclo de liderazgo de cuatro años, desempeñándose como presidente en 2011. Fue galardonado con el Premio a la Excelencia en la 
                                                     Educación en Física 2010 de la Sociedad Estadounidense de Física (con Priscilla Laws, Ronald Thornton y el Grupo de Física Basada en la 
                                                     Actividad). En 2011, él y el equipo del taller de Aprendizaje Activo en Óptica y Fotónica recibieron el Premio al Educador SPIE. En 2011, 
                                                     también fue Especialista Fulbright en Argentina y recibió la Medalla de la Red Latinoamericana de Educación en Física (LAPEN). 
                                                     Fue Especialista Fulbright en Japón en 2018.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                            <img class="h-80 w-80 rounded-lg" src={NiviaTomasa} alt=""/>
                                            <div>
                                                <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dra. Nivia Tomasa Álvarez Aguilar</h3>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">Licenciada en Educación (La Habana, Cuba). Doctora en Ciencias pedagógicas 
                                                    (Minsk, Bielorrusia). Actualmente, Profesora Titular A, de Tiempo Completo de la Facultad de Ingeniería Mecánica y Eléctrica de la Universidad Autónoma de Nuevo León, 
                                                    México. Miembro del Sistema Nacional de Investigadores de México (SNI), Área IV. Nivel II. Posee Reconocimiento Prodep. Es Miembro del Consejo Mexicano de Investigación Educativa. 
                                                    Posee más de 40 publicaciones científicas en revistas indexadas en bases de datos de prestigio nacional e internacional.
                                                     Ha sido coordinadora de 7 libros, autora de 2 libros y varios capítulos de libros y monografías. Miembro del comité científico 
                                                     y del consejo evaluador de 4 revistas indexadas (Cuba, España, Brasil, Costa Rica y Chihuahua). 
                                                     Ha presentado varias ponencias en congresos nacionales e internacionales. Ha asesorado 21 tesis de doctorado en temas educativos, más de 60 tesis de maestría, y 
                                                     20 tesis de Licenciatura. Ha presentado varias ponencias e impartido conferencias magistrales y talleres en diferentes congresos internacionales y nacionales. 
                                                     Además, ha impartido clases a nivel licenciatura, maestría y doctorado en diferentes materias del área educativa. Sus principales líneas de investigación son: 
                                                     “formación del profesorado universitario”, “formación del estudiante”, “mujeres y carreras de ingeniería”.</p>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-stone-700">
                                            <img class="h-80 w-80 rounded-lg" src={Lizbeth} alt=""/>
                                            <div>
                                                <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dra. Lizbeth Habib Mireles</h3>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">Doctora en Educación y Profesora Investigadora en la Universidad Autónoma de Nuevo León, Facultad de Ingeniería 
                                                    Mecánica y Eléctrica (FIME), miembro del Sistema Nacional de Investigadores, Nivel 1, área IV Ciencias de la conducta y la educación. Actualmente es vicepresidenta 
                                                    de la Asociación Mexicana del Estudio de la Educación Superior (AMEES), Miembro del Consejo Mexicano de Investigación Educativa, A.C., Sociedad Mexicana de Educación Comparada.
                                                     Fue parte del comité Científico del Congreso Internacional Virtual en Investigación e Innovación Educativa. Pre evaluador Comisión dictaminadora SNI 2021, evaluadora 
                                                     del Premio de investigación UANL 2018 a 2022, participa en Mujeres en la Ciencia UANL desde el 2015 al 2023. Evaluadora de Ferias de Ciencias Nuevo León y 
                                                     Expociencias Nacional 2019 a 2023. </p>
                                                <p class="text-sm font-semibold leading-6 text-neutral-300">Actualmente, Coordinadora de Formación e Innovación Docente de la Subdirección Académica de FIME y Fundadora del 
                                                     Cuerpo Académico Consolidado "Diseño de Modelos de Formación Integral del Ingeniero ante la Internacionalización". Con líneas de investigación: Innovación Educativa 
                                                     en los procesos de formación del ingeniero e Internacionalización en el diseño de los programas educativos. Ha publicado más tres decenas de artículos en revistas arbitradas 
                                                     e indexadas, es autora de libros y capítulos de libros. Asesora de estancias de investigación y dirección de tesis de licenciatura y posgrado.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <p class='text-gray-300 text-center pt-32 text-lg pb-4'>*Ponentes sujetos a cambios.</p>    
                        </div>         
                </div>
                <div class="bg-neutral-900 pt-24 px-40 text-justify">
                <h2 class="text-4xl font-medium tracking-tight text-white text-center px-52 ">Ponentes Tipo Taller</h2>
                <div class="mx-auto grid gap-x-8 gap-y-20 px-6 lg:px-12 xl:grid-cols-1 pt-10 pb-10" >
                    <ul role="list" class="grid gap-x-8 gap-y-12 md:grid-cols-2 col-start-1 sm:gap-y-5 xl:col-span-1">
                        <li>
                            <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-slate-800">
                                <img class="h-1/2 rounded-lg" src={imageCesarEduardo} alt=""/>
                                <div class='px-6 py-6'>
                                    <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dr. César Eduardo Mora Ley </h3>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-slate-800">
                                <img class="h-1/3 rounded-lg" src={DavidSokoloff} alt=""/>
                                <div>
                                    <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">David R. Sokoloff</h3>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class="text-4xl font-medium tracking-tight text-white text-center px-52 pt-32 ">Taller de Física Recreatica y Experimentos Formales de Nivel Medio Superior</h2>
                <div class="mx-auto grid gap-x-8 gap-y-20 px-6 lg:px-12 xl:grid-cols-1 pt-10 pb-24" >
                    <ul role="list" class="grid gap-x-8 gap-y-12 md:grid-cols-2 col-start-1 sm:gap-y-5 xl:col-span-1">
                        <li>
                            <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-slate-800">
                                <img class="h-auto rounded-lg" src={Francisco} alt=""/>
                                <div class=' px-6 py-6'>
                                    <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dr. Francisco Cabrera Hernández</h3>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center gap-x-10 rounded-lg px-5 py-5 bg-slate-800">
                                <img class="h-auto rounded-lg" src={JuanCarlosRuiz} alt=""/>
                                <div>
                                    <h3 class="text-base leading-7 tracking-tight text-white font-bold pb-2">Dr. Juan Carlos Ruiz Mendoza</h3>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    )
}
export default Ponentes;