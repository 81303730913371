import React from 'react';
import image from './images/space.png';
import video from './Videos/Comp.mp4';
import uanlLogo from './images/UANL_G.png';
import excelencia from './images/Excelencia_D.png';
import fcfmLogo from './images/FCFM_G.png';


const Hero = () =>{
    return (
        <body id="inicio" >
            <div className='w-full h-[95vh]' >
                
                {/*<img src={image} className='w-full h-full object-cover'></img>*/}
                <video autoPlay loop muted class="w-full h-full object-cover">
                    <source src={video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>


                <div className='m-auto'>
                    <div className='absolute top-[82.6%]  bg-white bg-opacity-40 py-2 '>
                            <div class="grid grid-cols-3 justify-items-center">
                                <div class="items-center sm:size-4/12 size-4/6"><img src={uanlLogo} alt={uanlLogo}/></div>
                                <div class="items-center sm:size-3/12 size-4/6"><img src={excelencia} alt={excelencia} /></div>
                                <div class="items-center sm:size-4/12 size-4/6"><img src={fcfmLogo} alt={fcfmLogo} /></div>
                        </div>
                    
                    </div>
                    
                    <div className='absolute top-[26%] w-full md:-[50%] max-w-[1100px] h-full flex flex-col' >
                        <div class="sticky px-10">
                            <h1 className='hidden sm:flex text-5xl text-white px-7' >XVII Reunión Anual de la Asociación Americana de Profesores de Física</h1>
                            <h1 className='hidden sm:flex text-5xl py-5 text-white px-7' >AAPT-MX 2024</h1>
                            <h2 className='hidden sm:flex text-3xl py-5 text-white px-7'>Nuevas Prácticas de Aprendizaje Activo de la Física</h2>
                            <h2 className='hidden sm:flex text-3xl py-20 text-white px-7 font-normal'>21, 22 y 23 de Noviembre</h2>

                            <h1 className='sm:hidden text-3xl text-white px-7' >XVII Reunión Anual de la Asociación Americana de Profesores de Física</h1>
                            <h1 className='sm:hidden text-3xl py-5 text-white px-7' >AAPT-MX 2024</h1>
                            <h2 className='sm:hidden text-xl py-5 text-white px-7'>Nuevas Prácticas de Aprendizaje Activo de la Física</h2>
                            <h2 className='sm:hidden text-xl py-20 text-white px-7 font-normal'>21, 22 y 23 de Noviembre</h2>
                        </div>
                        
                    </div>
                </div>
            </div>
        </body>
    );
}


export default Hero