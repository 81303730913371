import React, {useState, useEffect} from 'react';

const Footer = () =>{
    return (
        <div className='pt-0.5' >
            <div className='hidden sm:flex w-full pb-8 pt-8 bg-black justify-center'>
                <ul className='sm:flex justify-center px-24 text-white '>
                    <li>
                        <p class="text-sm">Cualquier duda, sugerencia o asistencia requerida, 
                            comuníquese al siguiente correo electrónico:
                            Omar.gonzalezmz@uanl.edu.mx</p>
                    </li>
                </ul>
            </div>
            <div className='sm:hidden w-full pb-8 pt-8 bg-black justify-center'>
                <ul className='sm:flex justify-center px-10 text-white text-center '>
                    <li>
                        <p class="text-xs">Cualquier duda, sugerencia o asistencia requerida, 
                        comuníquese al siguiente correo electrónico:
                        Omar.gonzalezmz@uanl.edu.mx</p>
                    </li>
                    
                </ul>
            </div>
        </div>

    )    

}

export default Footer;