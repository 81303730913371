import hotel from './images/hotel.png';
import React, {useState, useEffect} from 'react';


const Alojamiento = () => {
    const [nav, setNav] = useState(false)
const handleNav = () => {
    setNav(!nav)
}
    return (

        <div id="alojamiento">
            <div class=' sm:hidden pt-4 bg-gradient-to-t from-blue-950 to-slate-700'>
                <div class="pt-14">
                    <div class="pt-12">
                        <h1 class="text-3xl px-3 pb-10 text-center font-bold tracking-tight text-white sm:text-3xl">Alojamiento</h1>
                        <div class="px-12">
                            <div class="pb-20">
                                <div>
                                    <img class="h-auto w-auto rounded-md object-cover" src={hotel} alt=""/>
                                    <p className="text-lg pt-14 pb-5 text-white" >Detalles del hotel:</p>
                                    <p className="text-sm pb-5 text-white" >Hotel: Wyndham Garden Monterrey Norte</p>
                                    <p className="text-sm  pb-5 text-white" >Dirección: Av. Universidad 501 Nte. San Nicolás
                                    de los Garza, N.L</p>
                                    <p className="text-sm  pb-5 text-white" >Tarifas Autorizadas: </p>
                                    <p className="text-sm  pb-5 text-white" >&#x2022; Sencilla o Doble $1,606.50 (Hasta 1 y 2 personas)</p>
                                    <p className="text-sm  pb-14 text-white" >&#x2022; Triple o Cuádruple $1,808.80 (Hasta 3 y 4 personas)</p>
                                    <div className='w-1/2 mx-auto text-center pb-10'>
                                        <a href="https://aaptmx-2024.com/flyer.pdf" className="text-lg text-orange-300 cursor-pointer"><u><b>Mas información</b></u></a>
                                    </div>
                                    <div class="google-maps">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.8144206897196!2d-100.29953767332458!3d25.743649962226797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866294f4e30479bf%3A0x43b5541cb576043b!2sWyndham%20Garden%20Monterrey%20Norte!5e0!3m2!1sen!2smx!4v1721884341206!5m2!1sen!2smx"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div class='hidden sm:flex pt-14 bg-gradient-to-t from-black to-sky-950'>
                <div class="pt-14 w-1/2 mx-auto">
                    <div class="px-16 pb-10 text-center">
                        <h1 class="text-4xl pb-10 font-medium tracking-tight text-white sm:text-5xl">Alojamiento</h1>

                        <p className="px-20 pb-0.5 rounded-lg bg-white" ></p>
                    </div>
                    
                    <div class="pb-10 pt-5 px-16">
                        <div>
                            <div class="w-1/2 mx-auto pb-14 ">
                                <img class="h-auto w-auto rounded-md object-cover" src={hotel} alt=""/>
                            </div>
                            <p className="px-20 pb-0.5 rounded-lg bg-white" ></p>
                            <p className="text-lg px-20 pt-14 pb-5 text-white" >Detalles del hotel:</p>
                            <p className="text-sm px-20 pb-3 text-white" >Hotel:    Wyndham Garden Monterrey Norte</p>
                            <p className="text-sm px-20 pb-3 text-white" >Dirección:    Av. Universidad 501 Nte. San Nicolás
                            de los Garza, N.L</p>
                            <p className="text-sm px-20 pb-3 text-white white-space: pre-wrap" >Tarifas Autorizadas: &emsp;&emsp;&#x2022; Sencilla o Doble $1,606.50 (Hasta 1 y 2 personas)</p>
                            <p className="text-sm px-20 pb-10 text-white white-space: pre-wrap" >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; &#x2022; Triple o Cuádruple $1,808.80 (Hasta 3 y 4 personas)</p>
                            <div className='w-1/2 mx-auto text-center pb-10'>
                                <a href="https://aaptmx-2024.com/flyer.pdf" className="text-lg text-orange-300 cursor-pointer"><u><b>Mas información</b></u></a>
                            </div>
                            <div class="google-maps">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.8144206897196!2d-100.29953767332458!3d25.743649962226797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866294f4e30479bf%3A0x43b5541cb576043b!2sWyndham%20Garden%20Monterrey%20Norte!5e0!3m2!1sen!2smx!4v1721884341206!5m2!1sen!2smx"/>
                            </div>

                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        


    )
}
export default Alojamiento