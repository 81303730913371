import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';

const Navbar = () =>{
const [nav, setNav] = useState(false)
const handleNav = () => {
    setNav(!nav)
}

    return (
    <header class="fixed w-full">
        <div className='w-full min-h-[50px] flex' >
            <div className='hidden sm:flex w-full z-10 bg-gray-950 justify-between'>
                <ul className='sm:flex justify-between text-white '>
                    <li className='px-5 text-orange-100'>
                        <a href="#inicio">Inicio</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#convocatoria">Convocatoria</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#registro" >Registro</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#programa">Programa</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#ponentes">Ponentes</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#comite">Comité</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#ubicacion">Ubicación</a>
                    </li>
                    <li className='px-5 text-orange-100'>
                        <a href="#alojamiento">Alojamiento</a>
                    </li>
                </ul>
            </div>
            

            <div className='sm:hidden justify-between absolute'>
                <div className='pt-3 ps-3'>
                    <FaBars size={45}  onClick={handleNav} className='mr-4 cursor-pointer bg-white object-cover rounded relative border-4 border-white shadow-xl'/>
                </div>
            </div>
            <div onClick={handleNav} className={nav ? 'overflow-y-hidden md:hidden ease-in duration-300 absolute text-grey-300 w-full left-0 top-0 h-screen bg-black/80 px-4 py-3 flex flex-col' : 'absolute top-0 w-full h-screen left-[-100%] ease-in duration-200'}>
                <ul className='h-full w-full text-center pt-12'>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#inicio">Inicio</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#convocatoria">Convocatoria</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#registro" >Registro</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#programa">Programa</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#ponentes">Ponentes</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#comite">Comité</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#ubicacion">Ubicación</a>
                    </li>
                    <li className='text-2xl py-6 text-orange-100'>
                        <a href="#alojamiento">Alojamiento</a>
                    </li>
                </ul>
            </div>

        </div>
    </header>

    )    

}

export default Navbar;