import React from 'react';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Convocatoria from './components/Convocatoria';
import Registro from './components/Registro';
import Programa from './components/Programa';
import Ubicacion from './components/Ubicacion';
import Comite from './components/Comite';
import Ponentes from './components/Ponentes';
import Alojamiento from './components/Alojamiento';
import Footer from './components/Footer';

function App() {
  return (
    <div class="scroll-smooth">
        <NavBar/>
        <Hero/>
        <Convocatoria/>
        <Registro/>
        <Programa/>
        <Ponentes/>
        <Comite/>
        <Ubicacion/>
        <Alojamiento/>
        <Footer/>

    </div>
  );
}

export default App;
